import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { Globe } from "phosphor-react";
import { useState } from "react";
import { usePopper } from "react-popper";

function LanguageDropdown() {
  const { push, pathname, locale } = useRouter();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  // Locales
  const locales = {
    it: {
      label: "Italiano",
    },
    "en-US": {
      label: "English (US)",
    },
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            // @ts-ignore
            ref={setReferenceElement}
            aria-label="Switch language"
            className={`outline-none cursor-pointer text-3xl w-16 h-16 flex items-center justify-center transition lg:text-2xl lg:rounded lg:h-auto lg:w-auto lg:lg:p-2  ${
              open
                ? `bg-white text-brand-500 lg:text-gray-900 lg:bg-gray-200 lg:dark:bg-gray-100 lg:dark:text-gray-900`
                : `bg-white text-gray-900 lg:text-gray-900 lg:bg-gray-100 lg:dark:bg-gray-700 lg:dark:text-gray-100`
            }`}
          >
            <Globe />
          </Popover.Button>

          <AnimatePresence>
            <>
              <Popover.Panel
                as={motion.div}
                // @ts-ignore
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                className={`bg-white overflow-hidden rounded shadow my-1.5 text-base w-60 lg:w-32 lg:text-sm`}
                transition={{
                  timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                  duration: 0.1,
                }}
              >
                {Object.entries(locales).map(
                  ([key, value]: [string, any], index) => (
                    <button
                      key={index}
                      className={`${
                        locale === key && "text-brand-500"
                      } outline-none font-medium cursor-pointer py-3 whitespace-nowrap px-6 flex w-full lg:py-2 lg:px-4 active:bg-gray-100 lg:hover:bg-gray-100 focus:bg-gray-100`}
                      onClick={() => {
                        push(pathname, pathname, { locale: key });
                        Cookies.set("NEXT_LOCALE", key);
                      }}
                    >
                      <span>{value.label}</span>
                    </button>
                  )
                )}
              </Popover.Panel>
            </>
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
}

export default LanguageDropdown;
